import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import classNames from 'classnames'
import { formatIriToId } from 'helpers/utils/common'
import { JOB_PATH_PREFIX } from 'constants/routes'
import {
  AFTER_SALE_ALL_TYPES,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
  MANUAL_RESOLUTION_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import styles from './JobDetailsWorksiteJobsNavigationStyles'
import ChipStatus from './ChipStatus/ChipStatus'

const JobDetailsWorksiteJobsNavigation = ({
  classes,
  jobId,
  jobs,
  showStatus,
  translate,
}) => (
  <div className={classes.root}>
    <ul className={classes.tabs}>
      {jobs.map(job => (
        <li key={formatIriToId(job['@id'])} className={classes.tab}>
          <Link
            className={classNames({
              [classes.link]: true,
              [classes.activeLink]: job['@id'] === jobId,
            })}
            to={`${JOB_PATH_PREFIX}/${encodeURIComponent(job['@id'])}/show`}
          >
            {AFTER_SALE_ALL_TYPES.includes(job.type) && (
              <span className={classes.acronym}>
                {' '}
                [{translate(getAfterSaleJobAcronym(job))}]{' '}
              </span>
            )}
            {formatIriToId(job['@id'])}
            <ChipStatus
              showStatus={showStatus}
              status={job.substatus || job.status}
              invoiceStatus={job.invoiceStatus}
            />
          </Link>
        </li>
      ))}
    </ul>
  </div>
)

JobDetailsWorksiteJobsNavigation.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      minDateHo: PropTypes.string.isRequired,
    }),
  ),
  showStatus: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    tabs: PropTypes.string,
    tab: PropTypes.string,
    link: PropTypes.string,
    activeLink: PropTypes.string,
    acronym: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
}

JobDetailsWorksiteJobsNavigation.defaultProps = {
  jobs: [],
}

export default compose(
  i18n,
  withStyles(styles),
)(JobDetailsWorksiteJobsNavigation)

export function getAfterSaleJobAcronym(job) {
  switch (job.type) {
    case MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE:
      return 'resources.after_sale_jobs.diagnostic.type_acronym'
    case MANUAL_RESOLUTION_AFTER_SALE_TYPE:
      return 'resources.after_sale_jobs.resolution.type_acronym'
    default:
      return 'resources.after_sale_jobs.type_acronym'
  }
}
