import {
  INVOICE_STATE_AWAITING_VALIDATION,
  INVOICE_STATE_CONTESTED,
} from 'constants/invoices'

export const MANUAL_MATCHING_FORM_NAME = 'ManualMatching'
export const FIELD_SELECTED_PRO_IRI_NAME = 'selectedProIri'

export const JOB_ACCEPTED_STATUS = 'job_accepted'
export const JOB_IN_PROGRESS_STATUS = 'job_in_progress'
export const PENDING_REALIZATION_STATUS = 'pending_realization'
export const PENDING_START_STATUS = 'pending_start'
export const PENDING_PAYMENT_STATUS = 'pending_payment'
export const JOB_DONE_STATUS = 'job_done'
export const JOB_DECLINED_STATUS = 'job_declined'
export const PENDING_CANCELATION_STATUS = 'pending_cancelation'
export const JOB_CANCELED_STATUS = 'job_canceled'
export const PENDING_ASSIGNMENT_STATUS = 'pending_assignment'
export const PENDING_NEW_DATE_STATUS = 'pending_new_date'
export const PENDING_RESCHEDULING_STATUS = 'pending_rescheduling'
export const PENDING_MANUAL_ASSIGNMENT_STATUS = 'pending_manual_assignment'
export const PENDING_DATA_STATUS = 'pending_data'
export const SEARCH = 'search'
export const PENDING_MATCHING_STATUS = 'pending_matching'
export const DELIVERY_MODE_BY_CRAFTSMAN = 'delivery_by_craftsman'
export const DELIVERY_MODES = [DELIVERY_MODE_BY_CRAFTSMAN]
export const PRO_NOT_NOTIFIED_STATUS = 'not_notified'
export const PENDING_NOTIFICATION_STATUS = 'pending_notification'
export const JOB_EVENT_TYPE = {
  COMMENT: 'COMMENT',
  STATUS_CHANGE: 'STATUS_CHANGE',
  REQUIREMENTS_STATUS_CHANGE: 'REQUIREMENTS_STATUS_CHANGE',
  INCIDENT: 'INCIDENT',
  START_GEOLOCATION: 'START_GEOLOCATION',
  STOP_GEOLOCATION_CHECK_IN: 'STOP_GEOLOCATION_CHECK_IN',
  STOP_GEOLOCATION_PRO_BUTTON: 'STOP_GEOLOCATION_PRO_BUTTON',
  STOP_GEOLOCATION_UNEXPECTED: 'STOP_GEOLOCATION_UNEXPECTED',
  HO_CHECK_IN: 'HO_CHECK_IN',
  RE_MATCHING: 'RE_MATCHING',
}

export const TO_PENDING_NEW_DATE_STATUS_ENABLED = [
  PENDING_NEW_DATE_STATUS,
  PENDING_DATA_STATUS,
  PENDING_PAYMENT_STATUS,
  JOB_DONE_STATUS,
  PENDING_CANCELATION_STATUS,
  JOB_CANCELED_STATUS,
]

export const ORDER_UPDATE_NOT_VALID_STATUS = [
  JOB_DONE_STATUS,
  JOB_CANCELED_STATUS,
  JOB_DECLINED_STATUS,
  JOB_CANCELED_STATUS,
  PENDING_PAYMENT_STATUS,
  PENDING_CANCELATION_STATUS,
]

export const ORDER_UPDATE_PHONE_NUMBER_ONLY_STATUSES = [
  PENDING_START_STATUS,
  JOB_IN_PROGRESS_STATUS,
]

export const WAITING_FOR_RECEIPT_SIGNATURE = 'waiting_for_receipt_signature'
export const WAITING_FOR_RECEIPT = 'waiting_for_receipt'
export const WAITING_FOR_INVOICE = 'waiting_for_invoice'
export const WITH_CONTESTED_INVOICE = 'with_contested_invoice'
export const REFUSED_SIGNATURE = 'refused_signature'
export const BLOCKED_RECEIPT = 'blocked_receipt'
export const WITH_RECEIPT_WITH_RESERVES = 'with_receipt_with_reserves'
export const WAITING_FOR_RECEIPT_VALIDATION = 'waiting_for_receipt_validation'

export const TO_PENDING_NEW_DATE_SUBSTATUS_ENABLED = [
  WAITING_FOR_INVOICE,
  WITH_CONTESTED_INVOICE,
  REFUSED_SIGNATURE,
  WAITING_FOR_RECEIPT_SIGNATURE,
  BLOCKED_RECEIPT,
  WAITING_FOR_RECEIPT_VALIDATION,
]

export const RECEIPT_SIGNATURE_STATE_DONE = 'done'
export const RECEIPT_SIGNATURE_STATE_ACTIVE = 'active'
export const RECEIPT_SIGNATURE_STATE_FINISHED = 'finished'
export const RECEIPT_SIGNATURE_STATE_REFUSED = 'refused'
export const RECEIPT_SIGNATURE_STATE_MANUAL = 'manual'
export const RECEIPT_SIGNATURE_STATE_DRAFT = 'draft'
export const RECEIPT_SIGNATURE_STATE_EXPIRED = 'expired'
export const RECEIPT_SIGNATURE_STATE_BLOCKED = 'blocked'

export const JOB_TRANSITION_TO_PENDING_PAYMENT = 'to_pending_payment'
export const JOB_TRANSITION_RE_MATCHING = 'process_re_matching'
export const JOB_TRANSITION_TO_PENDING_NEW_DATE = 'to_pending_new_date'
export const JOB_TRANSITION_ASSIGNMENT = 'process_assignment'
export const JOB_TRANSITION_CANCEL_ASSIGNMENT = 'process_cancel_assignment'
export const JOB_TRANSITION_CANCEL_RESCHEDULE = 'process_rescheduling'
export const JOB_TRANSITION_PROCESS_CANCELED = 'process_canceled'
export const JOB_TRANSITION_PROCESS_TO_DONE = 'finish'
export const JOB_TRANSITION_PROCESS_JOB_START = 'process_job_start'
export const JOB_TRANSITION_TO_PENDING_CANCELATION = 'to_pending_cancelation'

export const HO_STATUS = [
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  PENDING_ASSIGNMENT_STATUS,
  PENDING_PAYMENT_STATUS,
]

export const STATUSES_DISPLAYING_PAYMENT_STEP = [
  PENDING_PAYMENT_STATUS,
  JOB_DONE_STATUS,
]

export const STATUSES_DISPLAYING_VALIDATE_BILLS = [
  JOB_IN_PROGRESS_STATUS,
  ...STATUSES_DISPLAYING_PAYMENT_STEP,
]

export const JOB_CANCELABLE_STATUSES = [
  PENDING_REALIZATION_STATUS,
  JOB_IN_PROGRESS_STATUS,
  PENDING_START_STATUS,
]

export const SHOW_BFT_TAB_STATUS = [...STATUSES_DISPLAYING_VALIDATE_BILLS]

export const SHOW_CERFA_TAB_STATUS = [...STATUSES_DISPLAYING_VALIDATE_BILLS]

export const CERFA_15497 = '15497'
export const CERFA_15498 = '15498'
export const CERFA_BOTH = '15497-15498'
export const CERFA_LABEL = {
  [CERFA_15497]: 'resources.cerfa.subForm.label15497',
  [CERFA_15498]: 'resources.cerfa.subForm.label15498',
  [CERFA_BOTH]: 'resources.cerfa.subForm.labelBoth',
}

export const SHOW_INVOICE_TAB_STATUS = [
  ...STATUSES_DISPLAYING_PAYMENT_STEP,
  WAITING_FOR_INVOICE,
  WITH_CONTESTED_INVOICE,
  INVOICE_STATE_AWAITING_VALIDATION,
]

export const MANUAL_MATCHING_COLUMNS_NAME = [
  'resources.jobs.proTable.column.ranking',
  'resources.jobs.proTable.column.legacyId',
  'resources.jobs.proTable.column.name',
  'resources.jobs.proTable.column.phoneNumber',
  'resources.jobs.proTable.column.isLM',
  'resources.jobs.proTable.column.removal',
  'resources.jobs.proTable.column.manualRating',
  'resources.jobs.proTable.column.countJobsDoneByMonth',
  'resources.jobs.proTable.column.notifications',
  'resources.jobs.proTable.column.proResponse',
]

export const GO_TO_PRO_COLUMNS_NAME = [
  'resources.jobs.proTable.column.legacyId',
  'resources.jobs.proTable.column.name',
  'resources.jobs.proTable.column.phoneNumber',
  'resources.jobs.proTable.column.isLM',
  'resources.jobs.proTable.column.removal',
  'resources.jobs.proTable.column.manualRating',
  'resources.jobs.proTable.column.notifications',
  'resources.jobs.proTable.column.proResponse',
]

export const SIMULATED_MATCHING_COLUMNS_NAME = [
  'resources.jobs.proTable.column.rankingProd',
  'resources.jobs.proTable.column.legacyId',
  'resources.jobs.proTable.column.name',
  'resources.jobs.proTable.column.phoneNumber',
  'resources.jobs.proTable.column.isLM',
  'resources.jobs.proTable.column.removal',
  'resources.jobs.proTable.column.countJobsDoneByMonth',
  'resources.jobs.proTable.column.rankingRating',
]

export const PAYMENT_STEP_FORM_NAME = 'PAYMENT_STEP_FORM'
export const COMMENT_FORM = 'commentForm'

export const BFT = 'bft'
export const CERFA = 'cerfa'
export const INFORMATIONS = 'informations'
export const PLANIFICATION = 'planification'
export const PICTURES = 'pictures'
export const MESSAGING = 'messaging'
export const HISTORY = 'history'
export const INCIDENTS = 'incidents'
export const STARTING_JOB = 'startingJob'
export const INVOICE = 'invoice'
export const STATUS_VISIBLE_WILDCARD = '*'
export const FEATURE_FLAGGED = 'feature-flagged'
export const PK_TYPE = 'package'
export const ANCHOR_NAVIGATION = [
  {
    key: INFORMATIONS,
    statusVisible: [STATUS_VISIBLE_WILDCARD],
    title: 'resources.jobs.navigation.informations',
  },
  {
    key: PLANIFICATION,
    statusVisible: [STATUS_VISIBLE_WILDCARD],
    title: 'resources.jobs.navigation.planification',
  },
  {
    key: STARTING_JOB,
    statusVisible: [PENDING_START_STATUS],
    title: 'resources.jobs.navigation.startingJob',
  },
  {
    key: CERFA,
    statusVisible: SHOW_CERFA_TAB_STATUS,
    title: 'resources.jobs.navigation.cerfa',
  },
  {
    key: BFT,
    statusVisible: SHOW_BFT_TAB_STATUS,
    title: 'resources.jobs.navigation.bft',
  },
  {
    key: PICTURES,
    statusVisible: [STATUS_VISIBLE_WILDCARD],
    showIfHasPictures: true,
    title: 'resources.jobs.navigation.pictures',
  },
  {
    key: MESSAGING,
    statusVisible: [FEATURE_FLAGGED],
    title: 'resources.jobs.navigation.messaging',
  },
  {
    key: INVOICE,
    statusVisible: SHOW_INVOICE_TAB_STATUS,
    showIfHasAcceptedJobPros: true,
    title: 'resources.jobs.navigation.invoices',
  },
  {
    key: INCIDENTS,
    statusVisible: [STATUS_VISIBLE_WILDCARD],
    typeVisible: [PK_TYPE],
    title: 'resources.jobs.navigation.incidents',
  },
  {
    key: HISTORY,
    statusVisible: [STATUS_VISIBLE_WILDCARD],
    title: 'resources.jobs.navigation.history',
  },
]

export const JOBS_STATUS = [
  {
    key: PENDING_DATA_STATUS,
    filters: { status: PENDING_DATA_STATUS },
  },
  {
    key: PENDING_MATCHING_STATUS,
    filters: { status: PENDING_MATCHING_STATUS },
  },
  {
    key: PENDING_ASSIGNMENT_STATUS,
    filters: { status: PENDING_ASSIGNMENT_STATUS },
  },
  {
    key: PENDING_MANUAL_ASSIGNMENT_STATUS,
    filters: { status: PENDING_MANUAL_ASSIGNMENT_STATUS },
  },
  {
    key: PENDING_NEW_DATE_STATUS,
    filters: { status: PENDING_NEW_DATE_STATUS },
  },
  {
    key: PENDING_RESCHEDULING_STATUS,
    filters: { status: PENDING_RESCHEDULING_STATUS },
  },
  {
    key: PENDING_REALIZATION_STATUS,
    filters: { status: PENDING_REALIZATION_STATUS },
  },
  {
    key: PENDING_START_STATUS,
    filters: { status: PENDING_START_STATUS },
  },
  {
    key: WAITING_FOR_RECEIPT,
    filters: {
      receiptSignatureStatus: [
        RECEIPT_SIGNATURE_STATE_DRAFT,
        RECEIPT_SIGNATURE_STATE_ACTIVE,
        RECEIPT_SIGNATURE_STATE_EXPIRED,
        'null',
      ],
    },
  },
  {
    key: BLOCKED_RECEIPT,
    filters: { receiptSignatureStatus: [RECEIPT_SIGNATURE_STATE_BLOCKED] },
  },
  {
    key: REFUSED_SIGNATURE,
    filters: { receiptSignatureStatus: [RECEIPT_SIGNATURE_STATE_REFUSED] },
  },
  {
    key: WAITING_FOR_INVOICE,
    filters: { invoiceStatus: [INVOICE_STATE_AWAITING_VALIDATION] },
  },
  {
    key: WITH_CONTESTED_INVOICE,
    filters: {
      status: JOB_IN_PROGRESS_STATUS,
      invoiceStatus: INVOICE_STATE_CONTESTED,
    },
  },
  {
    key: PENDING_PAYMENT_STATUS,
    filters: { status: PENDING_PAYMENT_STATUS },
  },
  {
    key: JOB_DONE_STATUS,
    filters: { status: JOB_DONE_STATUS },
  },
  {
    key: PENDING_CANCELATION_STATUS,
    filters: { status: PENDING_CANCELATION_STATUS },
  },
  {
    key: JOB_CANCELED_STATUS,
    filters: { status: JOB_CANCELED_STATUS },
  },
]

// @deprecated
export const TABBED_JOB_LIST = {
  [JOB_IN_PROGRESS_STATUS]: [
    {
      key: WAITING_FOR_RECEIPT,
      filterName: 'receiptSignatureStatus',
      filterValues: [
        RECEIPT_SIGNATURE_STATE_DRAFT,
        RECEIPT_SIGNATURE_STATE_ACTIVE,
        RECEIPT_SIGNATURE_STATE_EXPIRED,
        RECEIPT_SIGNATURE_STATE_REFUSED,
        'null',
      ],
    },
    {
      key: REFUSED_SIGNATURE,
      filterName: 'receiptSignatureStatus',
      filterValues: [RECEIPT_SIGNATURE_STATE_REFUSED],
    },
    {
      key: WAITING_FOR_INVOICE,
      filterName: 'invoiceStatus',
      filterValues: [INVOICE_STATE_AWAITING_VALIDATION],
    },
    {
      key: WITH_CONTESTED_INVOICE,
      filterName: 'invoiceStatus',
      filterValues: [INVOICE_STATE_CONTESTED],
    },
  ],
}

export const URGENT_JOB_DELAY_IN_DAYS = 3
export const JOB_EVENT_HISTORY_PER_PAGE = 25
export const JOB_EVENT_HISTORY_PAGE = 0

// Tabs from ManualMatching
export const TAB_MATCHING_MATCHING_PROS = 0
export const TAB_MATCHING_PROS = 1
export const TAB_MATCHING_RELIABLE = 2
export const TAB_MATCHING_AUTO_RANKING = 3

export const JOB_DETAILS_TAB_OFFSET = 180
export const JOB_DETAILS_OFFSET_ACTIVE_ANCHOR = 200

export const OLD_JOB_PICTURE_ORIGINS = {
  HO: 'ho',
  CHECKIN: 'checkin',
  REALIZATION: 'realization',
  CHECKOUT: 'checkout',
}

export const OLD_JOB_PICTURE_ORIGINS_PRO = [
  OLD_JOB_PICTURE_ORIGINS.CHECKIN,
  OLD_JOB_PICTURE_ORIGINS.REALIZATION,
  OLD_JOB_PICTURE_ORIGINS.CHECKOUT,
]

export const JOB_PICTURE_ORIGINS = {
  HO: ['ho', 'ho_job_tracking', 'ho_receipt_reserve'],
  PRO: ['checkin', 'realization', 'checkout', 'diagnostic'],
}

export const CHECK_BOX_FIELD_NAME = 'checkBox'
export const TIMESLOT_DATE_FIELD_NAME = 'timeslotDate'
export const TIMESLOT_FIELD_NAME = 'timeslot'
export const PRO_FIELD_NAME = 'pro'
export const JOB_HABITATION_TYPES = ['apartment', 'house']
export const AFTER_SALE_TYPE = 'afterSale'
export const AUTO_AFTER_SALE_TYPE = 'autoAfterSale'
export const MANUAL_INITIAL_AFTER_SALE_TYPE = 'manualAfterSaleInitial'
export const MANUAL_RESOLUTION_AFTER_SALE_TYPE = 'manualAfterSaleResolution'
export const MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE = 'manualAfterSaleDiagnostic'
export const AFTER_SALE_ALL_TYPES = [
  AFTER_SALE_TYPE,
  AUTO_AFTER_SALE_TYPE,
  MANUAL_INITIAL_AFTER_SALE_TYPE,
  MANUAL_RESOLUTION_AFTER_SALE_TYPE,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
]
