import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { convertArrayToObject, convertCentsToEuros } from 'helpers/utils/common'
import { dateFormatter } from 'helpers/date'
import { FIRM_VATS, NOT_SUBJECT_TO_VAT, SUBJECT_TO_VAT } from 'constants/firms'
import {
  FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT,
  FIELD_INVOICE_ISSUED_AT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE,
  FIELD_INVOICE_ITEM_TYPE,
  FIELD_INVOICE_ITEM_SUBTOTAL,
  FIELD_INVOICE_ITEM_UNIT_PRICE,
  FIELD_INVOICE_ITEM_VAT_RATE,
  FIELD_INVOICE_ITEMS,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS,
  FIELD_INVOICE_SUBTOTAL,
  FIELD_INVOICE_TAX_TOTAL,
  FIELD_INVOICE_TOTAL,
  FIELD_INVOICE_ORDER_NUMBER,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER,
  FIELD_INVOICE_JOB_ID,
  INVOICE_ITEM_TYPE_OVERCHARGE,
  INVOICE_STATE_VALIDATED,
  INVOICE_STATE_PAID,
  INVOICE_STATE_CONTESTED,
  INVOICE_STATE_MANUAL,
  INVOICE_STATE_AWAITING_VALIDATION,
  INVOICE_STATE_NEW_SENDING_FAILED,
} from 'constants/invoices'
import { DATE_FORMAT } from 'constants/date'
import { FILTER_SALE_ALL_INVOICES_LIST_FORM } from 'constants/forms'
import { invoiceIdSelector, invoiceStatusSelector } from '../jobs/jobSelectors'

export const initialState = {
  list: [],
  searchParams: {
    jobId: '',
    number: '',
    orderNumber: '',
    proName: '',
    homeOwnerName: '',
    states: [],
    issuedAt: [],
    types: [],
    page: 0,
    rowsPerPage: 50,
    total: 0,
    sort: 'asc',
    orderBy: 'stateUpdatedAt',
  },
  currentInvoice: {},
  currentInvoiceCart: {},
  displayedInvoice: null,
  dialogs: {
    createTravelCompensationPurchaseInvoice: {
      displayed: false,
    },
    createPurchaseInvoice: {
      displayed: false,
    },
  },
  additionalInvoices: {
    invoiceNatures: [],
    invoiceLineTypes: [],
    providers: [],
    amounts: [],
    rebilling: {},
    rebilling_customers: [],
    additionalInvoicesInLine: {
      invoiceNatures: {},
      invoiceLineTypes: {},
      providers: {},
      rebillingCustomers: {},
    },
  },
}

const firmVats = convertArrayToObject(FIRM_VATS, 'key')

const invoiceSelector = state => get(state, 'invoices')

export const invoicesListSelector = createSelector(invoiceSelector, state =>
  get(state, 'list', []),
)

export const searchParamsSelector = createSelector(invoiceSelector, state =>
  get(state, 'searchParams'),
)

export const currentInvoiceSelector = createSelector(invoiceSelector, state =>
  get(state, 'currentInvoice'),
)

export const currentInvoiceCartSelector = createSelector(
  invoiceSelector,
  state => get(state, 'currentInvoiceCart'),
)

export const invoiceIriSelector = createSelector(
  currentInvoiceSelector,
  state => get(state, '@id'),
)

export const invoiceDisputeSelector = createSelector(
  currentInvoiceSelector,
  state => get(state, 'reasonDispute'),
)

export const providerBillingDataSelector = createSelector(
  currentInvoiceSelector,
  state => get(state, 'providerBillingData'),
)

export const companySelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY]),
)

export const firmSubjectToVatSelector = createSelector(
  providerBillingDataSelector,
  state => {
    const subjectToVat = get(state, [
      FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
    ])

    switch (subjectToVat) {
      case true:
        return firmVats[SUBJECT_TO_VAT].key[0]
      case false:
        return firmVats[NOT_SUBJECT_TO_VAT].key[0]
      default:
        return ''
    }
  },
)

export const legalStatusSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS]),
)

export const capitalAmountSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT]),
)

export const registrationCitySelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY]),
)

export const registrationNumberSelector = createSelector(
  providerBillingDataSelector,
  state =>
    get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER]),
)

export const headquartersStreetSelector = createSelector(
  providerBillingDataSelector,
  state =>
    get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET]),
)

export const headquartersCitySelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY]),
)

export const headquartersPostcodeSelector = createSelector(
  providerBillingDataSelector,
  state =>
    get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE]),
)

export const streetSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET]),
)

export const citySelector = createSelector(providerBillingDataSelector, state =>
  get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY]),
)

export const postcodeSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE]),
)

export const vatNumberSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER]),
)

export const firstNameSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME]),
)

export const lastNameSelector = createSelector(
  providerBillingDataSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME]),
)

export const issuedAtSelector = createSelector(currentInvoiceSelector, state =>
  dateFormatter(get(state, [FIELD_INVOICE_ISSUED_AT]), DATE_FORMAT),
)

export const orderNumberSelector = createSelector(
  currentInvoiceSelector,
  state => get(state, [FIELD_INVOICE_ORDER_NUMBER]),
)

export const jobIdSelector = createSelector(currentInvoiceSelector, state =>
  get(state, [FIELD_INVOICE_JOB_ID]),
)

export const chipTypeInvoiceSelector = createSelector(
  invoiceStatusSelector,
  status => {
    switch (status) {
      case INVOICE_STATE_VALIDATED:
      case INVOICE_STATE_PAID:
        return 'success'
      case INVOICE_STATE_CONTESTED:
      case INVOICE_STATE_NEW_SENDING_FAILED:
        return 'error'
      case INVOICE_STATE_MANUAL:
      case INVOICE_STATE_AWAITING_VALIDATION:
      default:
        return 'info'
    }
  },
)

export const cartItemsSelector = createSelector(
  currentInvoiceCartSelector,
  state => {
    const items = get(state, FIELD_INVOICE_ITEMS, [])

    return items.map(item => ({
      ...item,
      [FIELD_INVOICE_ITEM_UNIT_PRICE]: convertCentsToEuros(
        item[FIELD_INVOICE_ITEM_UNIT_PRICE],
      ),
      [FIELD_INVOICE_ITEM_SUBTOTAL]: convertCentsToEuros(
        item[FIELD_INVOICE_ITEM_SUBTOTAL],
      ),
      [FIELD_INVOICE_ITEM_VAT_RATE]: convertCentsToEuros(
        item[FIELD_INVOICE_ITEM_VAT_RATE],
      ),
    }))
  },
)

export const cartFirmSubjectToVatSelector = createSelector(
  currentInvoiceCartSelector,
  state => get(state, [FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT]),
)

export const cartSubtotalSelector = createSelector(
  currentInvoiceCartSelector,
  state => convertCentsToEuros(get(state, [FIELD_INVOICE_SUBTOTAL])),
)

export const cartTaxTotalSelector = createSelector(
  currentInvoiceCartSelector,
  cart => convertCentsToEuros(get(cart, [FIELD_INVOICE_TAX_TOTAL])),
)

export const cartTotalSelector = createSelector(
  currentInvoiceCartSelector,
  cart => convertCentsToEuros(get(cart, [FIELD_INVOICE_TOTAL])),
)

export const currentItemSubtotalSelector = (state, item) => {
  const currentItem = get(currentInvoiceCartSelector(state), item)
  return get(currentItem, FIELD_INVOICE_ITEM_SUBTOTAL)
}

export const currentItemIsOverchargeSelector = (state, item) => {
  const currentItem = get(currentInvoiceCartSelector(state), item)
  return (
    get(currentItem, FIELD_INVOICE_ITEM_TYPE) === INVOICE_ITEM_TYPE_OVERCHARGE
  )
}

export const noGeneratedInvoiceSelector = createSelector(
  invoiceIdSelector,
  invoiceStatusSelector,
  (id, status) => !id && status !== INVOICE_STATE_MANUAL,
)

export const isGeneratedInvoiceSelector = createSelector(
  invoiceIdSelector,
  invoiceStatusSelector,
  (id, status) => !!id && status !== INVOICE_STATE_MANUAL,
)

export const isInvoiceContestedSelector = createSelector(
  invoiceStatusSelector,
  status => status === INVOICE_STATE_CONTESTED,
)

export const isInvoiceAwaitingValidationSelector = createSelector(
  invoiceStatusSelector,
  status => status === INVOICE_STATE_AWAITING_VALIDATION,
)

export const getAllInvoicesListFormValues = state =>
  getFormValues(FILTER_SALE_ALL_INVOICES_LIST_FORM)(state)

export const displayedInvoiceSelector = createSelector(invoiceSelector, state =>
  get(state, 'displayedInvoice'),
)

export const selectCreateTravelCompensationPurchaseInvoiceDialogDisplayed = state =>
  invoiceSelector(state).dialogs.createTravelCompensationPurchaseInvoice
    .displayed

export const selectCreatePurchaseInvoiceDialogDisplayed = state =>
  invoiceSelector(state).dialogs.createPurchaseInvoice.displayed

export const additionalInvoiceSelector = createSelector(
  invoiceSelector,
  state => get(state, 'additionalInvoices', []),
)

export const additionalInvoiceNaturesSelector = createSelector(
  additionalInvoiceSelector,
  state => {
    const invoiceNatures = get(state, 'invoiceNatures', [])
    const naturesItem = invoiceNatures.map(item => ({
      label: item.label,
      code: item.code,
    }))
    return naturesItem
  },
)

export const additionalInvoiceLineTypesSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'invoiceLineTypes', []),
)

export const additionalProvidersSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'providers', []),
)

export const additionalAmountOptionsSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'amounts', []),
)

export const additionalRebillingSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'rebilling', []),
)

export const additionalRebillingCustomersSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'rebilling_customers', []),
)

export const additionalInvoiceNaturesInLineSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'additionalInvoicesInLine.invoiceNatures', []),
)

export const additionalInvoiceTypesInLineTypesSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'additionalInvoicesInLine.invoiceLineTypes', []),
)

export const additionalProvidersInLineSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'additionalInvoicesInLine.providers', []),
)

export const additionalRebillingCustomersInLineSelector = createSelector(
  additionalInvoiceSelector,
  state => get(state, 'additionalInvoicesInLine.rebillingCustomers', []),
)
